import React, { useState } from "react";
import { Form, Button, Modal, Col, Row } from "antd";
import useStore from "../../../../../../store";
import { observer } from "mobx-react";
import { vsmNotify } from "../../../../../../config/messages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { DRF_status } from "../../../../../../utils/GlobalFunction";

const CompletedBookingComponent = observer((props) => {
	const [form] = Form.useForm();
	const {
		ManageZFormsStore,
	} = useStore();
	const [saving, setSaving] = useState();

	// Make function call to delete existing record
	const handleSubmit = (data) => {
		setSaving(true);
		data.id = ManageZFormsStore.viewValues.id;
		data.remarks = ManageZFormsStore.completedBookingValues.remarks;
		ManageZFormsStore.CompletedBooking(data)
			.then((data) => {
				close();
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => {
				setSaving(false);
			});
	};

	// reset form and close add form
	const close = () => {
		props.close();
		form.resetFields();
	};

	return ManageZFormsStore.viewValues ? (
		<Modal
			centered
			className="addModal"
			title={"Completed (" + ManageZFormsStore.viewValues.id + ")"}
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onCancel={props.close}
			cancelButtonProps={{ style: { display: "none" } }}
			okButtonProps={{ style: { display: "none" } }}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={close}
				>
					No
				</Button>,
				<Button
					key="1"
					form="completedForm"
					loading={saving}
					htmlType="submit"
					type="primary"
				>
					Yes
				</Button>,
			]}
		>
			<Form form={form} id="completedForm" onFinish={handleSubmit}>
				{
					<Row align="middle">
						<Col span={24} className="text-center">
							<h3>
								I have done all the Z-Form sections and processes and also got approval where required, so I would like to share it with the accountant for further auditing.
							</h3>
							{
								(!ManageZFormsStore.viewValues.booking_drf ||
									ManageZFormsStore.viewValues.booking_drf?.status !== 20) && (
									<>
										<span className="text-danger">
											Note: The DRF status is not completed for this Z-Form. Do you still want to complete the Z-Form?
										</span><br /><span>
											Current DRF Status : {ManageZFormsStore.viewValues?.booking_drf?.status ? DRF_status[ManageZFormsStore.viewValues.booking_drf.status] : 'Not Applied'}
										</span>
									</>
								)
							}
						</Col>
					</Row>
				}
			</Form>
		</Modal>
	) : null;
});

export default CompletedBookingComponent;
